import md5 from "md5";
import { fetchApi } from "config/ApiConfig";
import DV from "variables/DV";
import { CLID, SERVER_BASE_URL } from "config/Env";
import { getParamsFromObject } from "../helper/helper";

//h: token
export const getAccessToken = (token) => () => {
    let payload = {
        grant_type: "convert_token",
        client_id: CLID,
        token,
        backend: "facebook",
    };
    return fetchApi("oauth/convert-token/", payload, "post");
};

export const getAccessToken2 = (token) => () => {
    let payload = {
        token,
        provider: "facebook",
    };
    return fetchApi("auth/oauth2/convert-token/", payload, "post",null, "3.0");
};

export const refreshToken = () => () => {
    let payload = {
        refresh_token: DV.refreshToken,
    };
    return fetchApi("auth/oauth2/refresh-token/", payload, "post",null, "3.0");
};

//h:company
export const postJoinCompanyByInviteCode = (code) => () =>
    fetchApi(
        "users/me/staffs/",
        { seller_invite_code: code },
        "post",
        null,
        "2.0"
    );

export const getPaymentMethods = () =>
    fetchApi("company/" + DV.company.id + "/payment-methods/", null, "get");

//h: products
export const getProducts = (params = "") => () =>
    fetchApi(
        "companies/" + DV.company.id + "/products/" + params,
        null,
        "get",
        null,
        "2.0"
    );

//h: seller
export const getSellerProfile = (params = "") =>
    fetchApi("users/me/" + params, null, "get", null, "2.0");

export const postStaffDevice = ({ staffId, deviceId }) =>
    fetchApi(
        "companies/" + DV.company.id + "/staffs/" + staffId + "/devices/",
        { device_id: deviceId },
        "post",
        null,
        "2.0"
    );

export const getChannels = () =>
    fetchApi("seller/company/" + DV.company.id + "/channels/", null, "get");

export const getListSeller = (params) => () => {
    return fetchApi(
        "company/" + DV.company.id + "/staffs/" + params || "",
        null,
        "get"
    );
};

export const getListSellerV2 = (params) => () => {
    if (!params) {
        params = "?source=center";
    } else {
        params += "&source=center";
    }
    return fetchApi(
        "companies/" + DV.company.id + "/staffs/" + params,
        null,
        "get",
        null,
        "2.0"
    );
};

export const getCallAccount = () => () =>
    fetchApi(
        "companies/" +
            DV.company.id +
            "/staffs/" +
            DV.user.last_staff.id +
            "/call-account/",
        null,
        "get",
        null,
        "2.0"
    );
//
// export const getHealCheckPhones = () => () =>
//     fetchApi(
//         "companies/" +
//             DV.company.id +
//             "/staffs/" +
//             DV.user.last_staff.id +
//             "/heal-check-phones/",
//         null,
//         "get",
//         null,
//         "2.0"
//     );

export const getCallInfo = (data) => () =>
    fetchApi(
        "inboxes/incoming-call/",
        data,
        "post",
        null,
        "2.0"
    );

export const getCallOutgoingInfo = (data) => () =>
    fetchApi(
        "inboxes/outgoing-call/",
        data,
        "post",
        null,
        "2.0"
    );

// export const postCallRequest = ({ pbx, extension, phone, aor }) => () =>
//     fetchApi(
//         "inboxes/outgoing-call/",
//         { pbx, extension, phone, aor },
//         "post",
//         null,
//         "2.0"
//     );

export const postRating = (id, data) => () =>
    fetchApi(
        "companies/" + DV.company.id + "/inboxes/" + id + "/rating",
        data,
        "post",
        null,
        "2.0"
    );



export const getPreview = ({ url }) => () =>
    fetchApi("inboxes/preview/", { url }, "post", null, "2.0");

export const changeSeller = ({ id, data }) => () =>
    fetchApi("inbox/" + id + "/change-seller/", { target_seller: data }, "put");
export const ownerChangeSeller = ({ id, data }) => () =>
    fetchApi(
        "companies/" + DV.company.id + "/inboxes/" + id + "/sellers",
        data,
        "post",
        null,
        "2.0"
    );

//h: inbox
export const getInboxMessages = (
    id,
    before = 0,
    after = 0,
    q = "",
    limit
) => () => {
    let obj = {
        before: before ? before - 1 : null,
        after: after ? after + 1 : null,
        q: q || null,
        limit: limit || null,
    };
    const { filterMessage } = DV;

    if (filterMessage) {
        obj = { ...obj, ...filterMessage };
    }
    let params = getParamsFromObject(obj);

    return fetchApi(
        "companies/" + DV.company.id + "/inboxes/" + id + "/messages/" + params,
        null,
        "get",
        null,
        "2.0"
    );
};

export const getInboxList = (params = "") => () =>
    fetchApi(
        "companies/" + DV.company.id + "/inboxes/" + params,
        null,
        "get",
        null,
        "2.0"
    );

export const getInbox = (id) => () =>
    fetchApi(
        "companies/" + DV.company.id + "/inboxes/" + id,
        null,
        "get",
        null,
        "2.0"
    );

export const getInboxSummary = (id, data) => () =>
    fetchApi(
        "/inboxes/" + id + "/summary/",
        data,
        "get",
        null,
        "3.0"
    );

export const getInboxFailureDelivery = (id) => () =>
    fetchApi(
        "companies/" + DV.company.id + "/inboxes/" + id + "/failure-delivery/",
        null,
        "get",
        null,
        "2.0"
    );

export const updateInbox = (id, data) => () =>
    fetchApi(
        "companies/" + DV.company.id + "/inboxes/" + id,
        data,
        "patch",
        null,
        "2.0"
    );

export const putAcceptInbox = ({ id }) =>
    fetchApi("inbox/" + id + "/take/", null, "put");

export const getTagProducts = ({ id }) =>
    fetchApi("inbox/" + id + "/products/tags/", null, "get");

export const postTagProducts = ({ id, data }) =>
    fetchApi("inbox/" + id + "/products/tags/", data, "post");

export const postSendProducts = ({ id, data }) => {
    let temp = {
        hash: "ecd3c3c8a53cb5cc3d1e46eda5135382",
        products: data,
    };
    return fetchApi("inbox/" + id + "/products/", temp, "post");
};

export const putDoneInbox = (id) => () =>
    fetchApi("inbox/" + id + "/done/", null, "put");

export const postSendImages = ({ id, data }) =>
    fetchApi("inbox/" + id + "/images/", data, "post");

export const checkSendImagesAndFile = ({ id }) =>
    fetchApi("inbox/" + id + "/inspect/", null, "get");

export const postReplyComment = (id, data) => () =>
    fetchApi("message/" + id + "/reply/", data, "post");

export const postHideComment = ({ id, data }) =>
    fetchApi("message/" + id + "/hide/", data, "post");

export const postLikeComment = ({ id, data }) =>
    fetchApi("message/" + id + "/like/", data, "post");

export const postIgnoreInbox = ({ id, data }) =>
    fetchApi("inbox/" + id + "/ignore/", data, "post");

export const postSendMessage = ({ id, data }) =>
    fetchApi("inbox/" + id + "/send-message/", data, "post");

export const createMessage = (data) => () =>
    fetchApi("companies/" + DV.company.id + "/messages/", data, "post", null, "3.0");

export const postArchiveComment = ({ id, data }) =>
    fetchApi("message/" + id + "/archive/", data, "post");

export const postSendFile = ({ id, data }) =>
    fetchApi("inbox/" + id + "/attachments/", data, "post");

export const postMakeInboxOrder = (id, data) => () =>
    fetchApi("inbox/" + id + "/orders/create/", data, "post");

//h: address
export const getProvinces = () => () =>
    fetchApi("address/provinces/", null, "get");

export const getDistricts = (id) => () =>
    fetchApi("address/" + id + "/districts/", null, "get");

export const getWards = (id) => () =>
    fetchApi("address/" + id + "/wards/", null, "get");

//h: fcm
export const postDevice = (data) => {
    const { detect } = require("detect-browser");
    const browser = detect();
    let device = {
        name: browser.name,
        type: "web",
        device_id: md5(data),
        registration_id: data,
    };
    return fetchApi("fcm/devices/", device, "post");
};

//h: segment
export const getSegments = () =>
    fetchApi("company/" + DV.company.id + "/segment/list", null, "get");

export const putSegment = ({ id, data }) =>
    fetchApi("inbox/" + id + "/switch-segment/", data, "put");

export const createSegment = (data) => () =>
    fetchApi(
        "companies/" + DV.company.id + "/segments/",
        data,
        "post",
        null,
        "2.0"
    );

//h: send order confirm form
export const postSendOrderConfirmForm = (id, data) => () =>
    fetchApi("inbox/" + id + "/order/form/", data, "post");

export const getCustomerOrderForm = (params) => () => {
    const {
        token,
        id,
        discount_percentage,
        discount_value,
        cod_amount,
        cod_reason,
        cod_type,
        discount_reason,
    } = params;
    const urlParams = getParamsFromObject({
        token,
        id,
        discount_percentage,
        discount_value,
        cod_amount,
        cod_reason,
        cod_type,
        discount_reason,
    });
    return fetchApi("customer/form" + urlParams, null, "get");
};

export const postSubmitCustomerOrderForm = (params, data) => () => {
    // let params = '?token=' + token + '&id=' + id + '&discount_percentage=' + data.discount_percentage + '&discount_value=' + data.discount_value;
    let urlParams = getParamsFromObject(params);
    return fetchApi("customer/form/" + urlParams, data, "post");
};

export const getCustomerHistory = (id) => () =>
    fetchApi(
        `companies/${DV.company.id}/inboxes/${id}/histories`,
        null,
        "get",
        null,
        "2.0"
    );

export const getQuickReplies = (code = "", id) => () =>
    fetchApi(
        `companies/${DV.company.id}/quick-replies/?code=${code}&inbox_id=${id}&source=center`,
        null,
        "get",
        null,
        "2.0"
    );

export const sendQuickReplies = (inboxId, payload) => () =>
    fetchApi(
        `companies/${DV.company.id}/inboxes/${inboxId}/messages/`,
        payload,
        "post",
        null,
        "2.0"
    );

export const getCurrentSubscription = (params = "") => () =>
    fetchApi(
        "companies/" + DV.company.id + "/subscriptions/current" + params,
        null,
        "get",
        null,
        "2.0"
    );

//h: order
export const getOrders = (params = "") => () =>
    fetchApi(
        "companies/" + DV.company.id + "/orders/" + params,
        null,
        "get",
        null,
        "2.0"
    );
export const postMakeCompanyOrder = (data) => () =>
    fetchApi(
        "companies/" + DV.company.id + "/orders/",
        data,
        "post",
        null,
        "2.0"
    );
export const cancelOrder = (id) => () =>
    fetchApi(
        "companies/" + DV.company.id + "/orders/" + id,
        null,
        "delete",
        null,
        "2.0"
    );
export const confirmOrder = (id, data) => () =>
    fetchApi(
        "companies/" + DV.company.id + "/orders/" + id,
        data,
        "patch",
        null,
        "2.0"
    );
export const addGHTK = (data) => () =>
    fetchApi("owner/accounts/ghtk/add-token", data, "post");
export const createExtraOrder = (id, data) => () =>
    fetchApi(
        "companies/" + DV.company.id + "/orders/" + id + "/extra-orders",
        data,
        "post",
        null,
        "2.0"
    );
export const getShipmentServices = (id = 148, data) => () =>
    fetchApi(
        "companies/" + DV.company.id + "/orders/" + id + "/shipping-services",
        data,
        "post",
        null,
        "2.0"
    );

//h: shipping services
export const getShipmentAccounts = () => () =>
    fetchApi("company/" + DV.company.id + "/shipment-accounts/", "get");
export const createShipmentService = (data) => () =>
    fetchApi(
        "companies/" + DV.company.id + "/shipment_services/",
        data,
        "post",
        null,
        "2.0"
    );
export const deleteShipmentService = (id) => () =>
    fetchApi(
        "companies/" + DV.company.id + "/shipment_services/" + id,
        null,
        "delete",
        null,
        "2.0"
    );
export const updateShipmentService = (data, id) => () =>
    fetchApi(
        "companies/" + DV.company.id + "/shipment_services/" + id,
        data,
        "put",
        null,
        "2.0"
    );
export const disableShipmentService = (id) => () =>
    fetchApi("company/shipment-accounts/" + id + "/disable", null, "put");
export const enableShipmentService = (id) => () =>
    fetchApi("company/shipment-accounts/" + id + "/enable", null, "put");

//h: pick address
export const getPickAddresses = () => () =>
    fetchApi("company/" + DV.company.id + "/pick-address/list", null, "get");
export const addPickAddress = (data) => () =>
    fetchApi("company/" + DV.company.id + "/pick-address/create", data, "post");
export const deletePickAddress = (id) => () =>
    fetchApi("company/pick-address/" + id + "/delete", null, "delete");
export const updatePickAddress = (id, data) => () =>
    fetchApi("company/pick-address/" + id + "/update", data, "put");

export const getTags = () => () =>
    fetchApi(`companies/${DV.company.id}/tags/`, null, "get", null, "2.0");
export const getInboxTags = (inboxId) => () =>
    fetchApi(
        `companies/${DV.company.id}/inboxes/${inboxId}/tags/`,
        null,
        "get",
        null,
        "2.0"
    );
export const deleteTag = (id, inboxId) => () =>
    fetchApi(
        `companies/${DV.company.id}/inboxes/${inboxId}/tags/${id}`,
        null,
        "delete",
        null,
        "2.0"
    );
export const addTag = (id, inboxId) => () =>
    fetchApi(
        `companies/${DV.company.id}/inboxes/${inboxId}/tags/`,
        { tag: id },
        "post",
        null,
        "2.0"
    );

//h: preset
export const getPresets = () => () =>
    fetchApi(
        "companies/" +
            DV.company.id +
            "/staffs/" +
            (DV?.user?.last_staff?.id || DV?.user?.staffs?.[0]?.id) +
            "/inbox-filter-presets/",
        null,
        "get",
        null,
        "2.0"
    );
export const createPreset = (data) => () =>
    fetchApi(
        "companies/" +
            DV.company.id +
            "/staffs/" +
            (DV?.user?.last_staff?.id || DV?.user?.staffs?.[0]?.id) +
            "/inbox-filter-presets/",
        data,
        "post",
        null,
        "2.0"
    );
export const deletePreset = (id) => () =>
    fetchApi(
        "companies/" +
            DV.company.id +
            "/staffs/" +
            (DV?.user?.last_staff?.id || DV?.user?.staffs?.[0]?.id) +
            "/inbox-filter-presets/" +
            id +
            "/",
        null,
        "delete",
        null,
        "2.0"
    );
export const updatePreset = (id, data) => () =>
    fetchApi(
        "companies/" +
            DV.company.id +
            "/staffs/" +
            (DV?.user?.last_staff?.id || DV?.user?.staffs?.[0]?.id) +
            "/inbox-filter-presets/" +
            id +
            "/",
        data,
        "put",
        null,
        "2.0"
    );

export const closeShift = (data) => () =>
    fetchApi(
        "companies/" +
            DV.company.id +
            "/staffs/" +
            (DV?.user?.last_staff?.id || DV?.user?.staffs?.[0]?.id) +
            "/shift-management/",
        data,
        "patch",
        null,
        "2.0"
    );

export const createTicket = (data) => () =>
    fetchApi(
        "companies/" + DV.company.id + "/tickets/",
        data,
        "post",
        null,
        "2.0"
    );

export const setUserStatus = (status) => () =>
    fetchApi(
        "users/me/staffs/" + DV.user.last_staff.id,
        { user_selected_status: status },
        "patch",
        null,
        "2.0"
    );

export const getFanpages = (params = '') => () =>
    fetchApi('company/' + DV.company.id + '/fanpages/' + params, null, 'get');


export const getShopee = () => () =>
    fetchApi('companies/' + DV.company.id + '/shopee/accounts/', null, 'get', null, "2.0");

export const getSettings = () => () =>
    fetchApi('companies/' + DV.company?.id + '/settings/', null, 'get', null, '3.0');

export const getReplyCommands = () => () =>
    fetchApi('companies/' + DV.company.id + '/reply-commands/', null, 'get', null, '3.0');


export const getSystemNotification = ({page_size}) => () => fetchApi(`system-notifications/?page_size=${page_size}`,  null,  'get' ,null,'3.0');
